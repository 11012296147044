import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import HowItWorks from "../components/howitworks";
import SkillSet from "../components/skillset";
import WhyUs from "../components/why";
import CTA from "../components/cta";

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: imageSharp(fluid: { originalName: { eq: "developer-bg.jpg" } }) {
        fluid(quality: 90, maxWidth: 1920) {
          presentationWidth
          presentationHeight
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      mobile: imageSharp(fluid: { originalName: { eq: "01.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      web: imageSharp(fluid: { originalName: { eq: "03.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      backend: imageSharp(fluid: { originalName: { eq: "02.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
      others: imageSharp(fluid: { originalName: { eq: "10.jpg" } }) {
        fluid(maxWidth: 270, maxHeight: 180) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  `);

  const heroTitle = (
    <>
      Hire <em className="font-weight-light">Good</em> Developers
    </>
  );

  const skills = {
    title: "Finding the right skillset",
    sets: [
      {
        title: "Mobile Development",
        image: data.mobile.fluid,
        list: [
          "Apple Developers",
          "Android Developers",
          "iOS Developer",
          "React Native Developers",
          "Flutter Developers"
        ]
      },
      {
        title: "Web development",
        image: data.web.fluid,
        list: [
          "Front End Developers",
          "React.Js Developers",
          "AngularJS Developers",
          "Node.js Developers",
          "JavaScript Developers",
          ".NET Developers"
        ]
      },
      {
        title: "Back End",
        image: data.backend.fluid,
        list: [
          "PHP Developers",
          "WordPress Developers",
          "Java Developers",
          "Python Developers",
          "Ruby on Rails Developers",
          "Django Developers"
        ]
      },
      {
        title: "Others",
        image: data.others.fluid,
        list: [
          "Blockchain Developers",
          "AWS developers",
          "UI UX Designer",
          "Web Design"
        ]
      }
    ]
  };

  const whyUs = {
    title: (
      <>
        <span className="font-weight-normal">Why</span> Choose Us
      </>
    ),
    sets: [
      {
        title: "Dedicated developers",
        desc:
          "Every developer on Tech2Reach is a full-time software engineer. " +
          "This means they have proven abilities, their time is well-managed, " +
          "and they are dedicated to your company – and your company only."
      },
      {
        title: "Unique talent pool",
        desc:
          "By allowing you to interview and select talent directly, " +
          "Tech2Reach ensures you talented developers are not freelancers."
      },
      {
        title: "Thorough vetting process",
        desc:
          "Every talent on Tech2Reach is handpicked for quality and talent. " +
          "Forget choosing between freelancers – Tech2Reach shows you " +
          "high-quality, verified talent, every time."
      },
      {
        title: "Scale at will",
        desc:
          "Tech2Reach’s model is fine-tuned for quickly growing businesses " +
          "who need longer-term dedicated tech solutions. Leverage the HR and " +
          "management expertise of managing developers and save yourself " +
          "time. Add or subtract developers to your team quickly."
      }
    ]
  };

  return (
    <Layout>
      <SEO
        title="Developers"
        pathname="/developer/"
        image={data.hero.fluid}
        keywords={[
          "singapore",
          "hire",
          "tech",
          "foreign",
          "talent",
          "offshore",
          "outsource",
          "programmer",
          "software",
          "developer"
        ]}
      />
      <Hero
        background={data.hero.fluid}
        title={heroTitle}
        subTitle="Getting talents couldn't be any easier than this"
        buttonText="Get In Touch"
      />
      <HowItWorks
        title1="No hiring limits"
        desc1="Hire as many foreign talents as you want. Building an offshore developer team eliminate the issue of getting an EP/SPass."
        title2="Cost savings"
        desc2="Up to 50% cost saving using offshore developers as compared to engaging a local"
        title3="Dedicated talents"
        desc3="A staff fully dedicated to you. Not a freelancer. Doesn't handle other company's projects."
        title4="Don't sweat it"
        desc4="We manage and comply with the local regulations. Payroll, office space, welfare, legal, training, and all other management is taken care of."
      />
      <SkillSet skills={skills} />
      <WhyUs why={whyUs} />
      <CTA />
    </Layout>
  );
};

export default Index;
